import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components";

import logo from '../images/logo.svg'

const HeaderWrapper = styled.div`
  background: white;
  img {
    margin-bottom: 0;
  }
`;

const HeaderContainer = styled.div`
  margin: 0 auto;
  padding: 1rem 2rem;
`;

const Logo = styled.img`
  width: 100px;
  margin: 0;
`;

const Header = () => (
  <HeaderWrapper>
    <HeaderContainer>
        <Link to="/">
          <Logo src={logo} alt='davidp.io logo'/>
        </Link>
    </HeaderContainer>
  </HeaderWrapper>
)


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
