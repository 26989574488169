import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image";
import { Spring } from "react-spring/renderprops";
import {useStaticQuery, graphql} from "gatsby"
import {Helmet} from "react-helmet/es/Helmet";
import styled from "styled-components";

import "./layout.css"
import Header from "./header"
import Archive from "./archive";

const MainLayout = styled.main`
  max-width: 90%;
  margin: 1rem auto;
  display: grid;
  //grid-template-columns: 3fr 1fr;
  grid-gap: 40px;
`;

const Layout = ({children, location}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
      file(relativePath: {
        regex: "/bg/"
      }) {
        childImageSharp {
          fluid(maxWidth: 1000){
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          {
            name: 'description',
            content: data.site.siteMetadata.description
          },
          {
            name: 'keywords',
            content: ''
          },
        ]}
      />
      <Header siteTitle={data.site.siteMetadata.title}/>
      <MainLayout>
        <div> {children} </div>
      </MainLayout>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  location: {},
}

export default Layout
